// @flow

import React from 'react';
import { timeout } from 'promise-timeout';
import loadable from '@loadable/component';
import { IsomorphicRipple } from 'shared_components/common/preloader/IsomorphicRipple';

const LOADABLE_TIMEOUT = 10000;
const FallbackComponent = (
  <IsomorphicRipple isActive customStyles={{ backgroundColor: '#ffffff', minHeight: '150px' }} />
);

/**
 * CMS_MODULES_COMPONENTS
 *
 * Object of lazyloaded CMS modules. Each key in this object should be the same as the module key in API module response
 */
export const CMS_MODULES_COMPONENTS: Object = {
  'cms/module/content-html': loadable(
    () => timeout(import('shared_components/cms/modules/content/Html'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleContentHtml,
      fallback: FallbackComponent,
    },
  ),
  'cms/module/content-responsive': loadable(
    () => timeout(import('shared_components/cms/modules/content/Responsive'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleContentResponsive,
    },
  ),
  'cms/module/banner': loadable(
    () => timeout(import('shared_components/cms/modules/banner/Banner'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleBanner,
    },
  ),
  'cms/module/side-menu': loadable(
    () => timeout(import('shared_components/cms/modules/sidemenu/Sidemenu'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleSidemenu,
      fallback: FallbackComponent,
    },
  ),
  'cms/module/legal-agreement': loadable(
    () => timeout(import('shared_components/cms/modules/legal/Agreement'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleLegalAgreement,
    },
  ),
  'cms/module/testimonial': loadable(
    () =>
      timeout(import('shared_components/cms/modules/testimonial/Testimonial'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleTestimonial,
    },
  ),
  'cms/module/hero-carousel': loadable(
    () => timeout(import('shared_components/cms/modules/hero/Carousel'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleHeroCarousel,
      fallback: FallbackComponent,
    },
  ),
  'cms/module/collections': loadable(
    () =>
      timeout(import('shared_components/cms/modules/collections/Collections'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleCollections,
      fallback: FallbackComponent,
    },
  ),
  'cms/module/collection-art': loadable(
    () => timeout(import('shared_components/cms/modules/collections/Art'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleCollectionArt,
      fallback: FallbackComponent,
    },
  ),
  'cms/module/editorials': loadable(
    () => timeout(import('shared_components/cms/modules/editorial/Editorial'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleEditorial,
      fallback: FallbackComponent,
    },
  ),
  'cms/module/editorials-slider': loadable(
    () => timeout(import('shared_components/cms/modules/editorial/Slider'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleEditorialSlider,
      fallback: FallbackComponent,
    },
  ),
  'cms/module/testimonials': loadable(
    () =>
      timeout(import('shared_components/cms/modules/testimonial/Testimonials'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleTestimonials,
      fallback: FallbackComponent,
    },
  ),
  'cms/module/page-banner': loadable(
    () => timeout(import('shared_components/cms/modules/pagebanner/PageBanner'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModulePageBanner,
      fallback: FallbackComponent,
    },
  ),
  'cms/module/press': loadable(
    () => timeout(import('shared_components/cms/modules/press/Press'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModulePress,
      fallback: FallbackComponent,
    },
  ),
  'cms/module/button-boxes': loadable(
    () => timeout(import('shared_components/cms/modules/boxes/Button'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleButtonBoxes,
      fallback: FallbackComponent,
    },
  ),
  'cms/module/product-tabs': loadable(
    () => timeout(import('shared_components/cms/modules/product/Tabs'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleProductTabs,
    },
  ),
  'cms/module/faqs': loadable(
    () => timeout(import('shared_components/cms/modules/common/Function'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleFunction,
    },
  ),
  'cms/module/info-boxes': loadable(
    () => timeout(import('shared_components/cms/modules/boxes/Info'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleInfoBoxes,
    },
  ),
  'cms/module/trust-section': loadable(
    () => timeout(import('shared_components/cms/modules/info/TrustSection'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleTrustSection,
    },
  ),
  'cms/module/quiz-start': loadable(
    () => timeout(import('shared_components/cms/modules/quiz/Start'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleQuizStart,
    },
  ),
  'cms/module/company-reviews': loadable(
    () => timeout(import('shared_components/arts/reviews/Reviews'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.ArtReviews,
    },
  ),
  'cms/module/enquire-form': loadable(
    () => timeout(import('shared_components/cms/modules/forms/Enquire'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleEnquireForm,
    },
  ),
  'cms/module/info-reasons': loadable(
    () => timeout(import('shared_components/cms/modules/info/Reasons'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleInfoReasons,
    },
  ),
  'cms/module/art-slider': loadable(
    () => timeout(import('shared_components/cms/modules/art/Slider'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleArtSlider,
    },
  ),
  'cms/module/artists-section': loadable(
    () => timeout(import('shared_components/cms/modules/artist/Grid'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleArtistGrid,
    },
  ),
  'cms/module/articles-slider': loadable(
    () => timeout(import('shared_components/cms/modules/article/Slider'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleArticlesSlider,
    },
  ),
  'cms/module/filters-form': loadable(
    () => timeout(import('shared_components/cms/modules/filters/Form'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleFiltersForm,
      fallback: FallbackComponent,
    },
  ),
  'cms/module/exhibition-details': loadable(
    () => timeout(import('shared_components/cms/modules/exhibition/Details'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleExhibitionDetails,
    },
  ),
  'cms/module/slider': loadable(
    () => timeout(import('shared_components/cms/modules/common/sliders/Common'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleCommonSlider,
    },
  ),
  'cms/module/single-slider': loadable(
    () => timeout(import('shared_components/cms/modules/common/sliders/Single'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleSingleSlider,
    },
  ),
  'cms/module/artist-art-grid': loadable(
    () => timeout(import('shared_components/cms/modules/artist/ArtGrid'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleArtistArtGrid,
    },
  ),
  'cms/module/parallax-banner': loadable(
    () => timeout(import('shared_components/cms/modules/banner/Parallax'), LOADABLE_TIMEOUT),
    {
      resolveComponent: (components) => components.CmsModuleParallaxBanner,
    },
  ),
};
